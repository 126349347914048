
function ErrorPage () {

    return (
      <>


      <h3>Opps! Page Not Found</h3>
      <p>The URL you entered does not exist!</p>


      </>
    )
}

export default ErrorPage;
