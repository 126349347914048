import {Link} from "react-router-dom"

function Header () {
    
    return (
<>

      <div className="Header">
      <h2><Link to={"/"}>🔸 NC News 🔹</Link></h2>
      </div>
      
      </>
    )
}

export default Header