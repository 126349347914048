function Footer () {
  return (
    <>
    <div className="Footer">
    <p>Copyright © 2022 NC News. All rights reserved.</p>
    </div>
    </>
  )
}

export default Footer